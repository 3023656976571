<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Gallery</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Gallery Area -->
<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="gallery-title">
            <h2>Picture To Floza</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery1.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery1.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery2.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery2.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery3.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery3.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery4.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery4.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery5.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery5.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery6.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery6.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Gallery Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->